<template>
  <div class="show-big">
    <el-row :gutter="24" class="show">
      <div class="main" v-loading="loading">
        <el-row :gutter="24" class="show-item" style="margin-top: 40px;">
          <p class="date-time"><span style="color:#999;margin-right:10px;">刷新时间：</span>{{datetime}}</p>
          <el-col :span="8" class="rll">
            <div id="category" style="width:100%;height:350px"></div>
          </el-col>
          <el-col :span="8" class="map">
            <div class="maps">
              <div class="maps-box">
                <div id="maps" style="width:100%;height:450px"></div>
              </div>
              <div class="tips">
                <div class="tips-box">
                  <h2>{{mapsItem.name}}</h2>
                  <div class="content">
                    <p>
                      <span>人口总数：</span><span>{{mapsItem.info.person_count}}</span>
                    </p>
                    <p>
                      <span>用户总数：</span><span>{{mapsItem.info.user_count}}</span>
                    </p>
                    <div v-if="mapsItem.info.label.length > 0">
                      <p v-for="(item,index) in mapsItem.info.label" :key="index">
                        <span v-if="index == 0">主要病种：</span>
                        <span v-else style="color:transparent">主要病种：</span>
                        <!-- v-if="index == 0" <span v-else class="color:red">主要病种：</span> -->
                        <span>{{item}}</span>
                      </p>
                    </div>
                    <div v-else>
                      <p>
                        <span>主要病种：</span><span></span>
                      </p>
                    </div>
                    <p>
                      <span>统筹医务：</span><span>{{mapsItem.info.doctor}}</span>
                    </p>
                  </div>
                  <h2 class="back" @click="backCtiyDate" v-if="mapsItem.name !='仙居县' ">返回</h2>
                </div>
              </div>
              <div class="total">
                <h2>累计用户：</h2>
                <div class="ims" v-if="numList.length > 0">
                  <div class="i-block img-item" v-for="(item,index) in numList">
                    <img v-if="item != ',' && item != '，'" :src="require('../../assets/image/showbig/'+item+'.png')">
                    <img v-if="item == ',' || item == '，'" src="../../assets/image/showbig/comma.png">
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8" class="rll jb">
            <el-row :gutter="24" style="margin:0px">
              <el-col :span="12" class="jb-item i-block">
                <div id="pieHos" style="width:100%;height:280px"></div>
                <div class="jb-db">
                  <div class="jb-db-item i-block" v-for="item in jibingHos">
                    <span class="jb-icon" :style="{background:item.color}"></span>
                    <p>{{item.name}}<br><span class="person-span">{{item.person}}</span></p>
                  </div>
                </div>
              </el-col>
              <el-col :span="12" class="jb-item i-block">
                <div id="pieSick" style="width:100%;height:280px"></div>
                <div class="jb-db">
                  <div class="jb-db-item i-block" v-for="item in jibingSick">
                    <span class="jb-icon" :style="{background:item.color}"></span>
                    <p>{{item.name}}<br><span class="person-span">{{item.person}}</span></p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="show-item" style="margin-top: 25px;">
          <el-col :span="8" class="rll jb hx">
            <el-row :gutter="24" style="margin:0px;height:100%">
              <el-col :span="12" class="jb-item hx-item i-block">
                <div id="agepie" style="width:100%;height:350px"></div>
                <!-- <div class="jb-db">
                                    <div class="jb-db-item i-block hx-db-item" v-for="item in age">
                                        <p>{{item}}</p>                                        
                                    </div>
                                </div>  -->
              </el-col>
              <el-col :span="12" class="jb-item hx-item i-block">
                <div id="pie" style="width:100%;height:350px"></div>
                <!-- <div class="jb-db">
                                    <div class="jb-db-item i-block hx-db-item" v-for="item in edu">
                                        <p>{{item}}</p>                                      
                                    </div>
                                </div>  -->
              </el-col>
              <div class="tipss">
                <h2 class="i-block">总计：{{hxObj.sum}}</h2>
                <p class="i-block">
                  <img class="mr-10" src="../../assets/image/showbig/man.png" alt="男">
                  <span>{{hxObj.man}}</span>
                </p>
                <p class="i-block">
                  <img class="mr-10" src="../../assets/image/showbig/woman.png" alt="男">
                  <span>{{hxObj.woman}}</span>
                </p>
              </div>
            </el-row>
          </el-col>
          <el-col :span="8" class="map" style="padding:0px;">
            <div class="zb">
              <div class="zb_tittle"></div>
              <div class="zb_list" ref="zbList">
                <p v-for="(item,index) in list">
                  <img v-if="!item.flag" class="mr-10" src="../../assets/image/showbig/icon_c.png" alt="图标">
                  <img v-if="item.flag" class="mr-10" src="../../assets/image/showbig/icon_a.png" alt="图标">
                  <span v-if="!item.flag">{{item.name}} {{item.value}}</span>
                  <span v-if="item.flag" style="color:#fff">{{item.name}} {{item.value}}</span>
                </p>
              </div>
            </div>
          </el-col>
          <el-col :span="8" class="rll jb ryw">
            <div id="bar" style="width:100%;height:320px"></div>
          </el-col>
        </el-row>
      </div>
    </el-row>
  </div>
</template>
<script>
import lishui from "../../components/zhuji.json";
import echarts from "echarts";
import { ScreeApi } from "../../api/api";
import { constants } from "crypto";
export default {
  data() {
    return {
      obj: {},
      jibingHos: [], //疾病分布医院
      jibingSick: [], //疾病分布疾病
      age: [], //用户画像年龄
      edu: [], //用户画像教育程度
      mapsItem: {
        info: {
          label: [],
          doctor: [],
        },
        name: "",
        value: "",
      }, //地图提示
      cityItem: {}, //全市数据
      loading: false,
      list: [], //各区域占比
      zbHeight: 0,
      hxObj: {},
      numList: [],
      datetime: "",
    };
  },
  mounted() {
    this.getData();
    setInterval(() => {
      this.getData();
    }, 1800000);
  },
  methods: {
    //获取数据
    getData() {
      var that = this;
      this.loading = true;
      var obj = that.obj;
      this.$axios.post(ScreeApi, {}).then(
        (res) => {
          if (res.data.code == 1) {
            //刷新日期
            this.datetime = res.data.data.datetime;

            // 日浏览
            obj.datas1 = res.data.data.datas1;
            this.initCategory();

            //地图
            obj.datas2 = res.data.data.datas2;
            this.mapsItem = res.data.data.datas2.map_list[0];
            this.cityItem = res.data.data.datas2.map_list[0];
            this.numList = res.data.data.datas2.user_sum;
            var arr = res.data.data.datas2.list;
            for (var i = 0; i < arr.length; i++) {
              if (this.mapsItem.name == arr[i].name) {
                arr[i].flag = true;
              } else {
                arr[i].flag = false;
              }
            }
            this.list = arr;
            this.initMap();

            // 疾病分布
            obj.datas4 = res.data.data.datas4;
            this.jibingHos = res.data.data.datas4.hosObj.list;
            this.jibingSick = res.data.data.datas4.sickObj.list;
            this.initBing();

            //用户画像
            obj.datas3 = res.data.data.datas3;
            this.age = res.data.data.datas3.age;
            this.edu = res.data.data.datas3.edu;
            this.hxObj = {
              sum: res.data.data.datas3.sum,
              woman: res.data.data.datas3.women,
              man: res.data.data.datas3.man,
            };
            this.initPie();

            //日业务统计
            obj.datas5 = res.data.data.datas5;
            this.initBar();

            this.loading = false;
          } else {
          }
        },
        (err) => {}
      );
    },
    //折线图
    initCategory() {
      let categoryChart = echarts.init(document.getElementById("category"));
      categoryChart.setOption({
        legend: {
          show: false,
          data: this.obj.datas1.legend,
        },
        tooltip: {},
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.obj.datas1.date,
          axisLabel: {
            textStyle: {
              color: "#62B4BB",
              fontSize: "12",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "#62B4BB",
              fontSize: "12",
            },
          },
        },
        series: [
          {
            data: this.obj.datas1.sign, //y轴
            type: "line",
            label: {
              normal: {
                show: true,
                position: "top",
                color: "#2af1f6",
              },
            },
            itemStyle: {
              normal: {
                color: "#328299",
                lineStyle: {
                  color: "#328299", //线条颜色
                },
              },
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#328299" },
                  { offset: 1, color: "#1f3d71" },
                ]),
              },
            },
          },
        ],
      });
    },
    //地图
    initMap() {
      let that = this;
      let myChart = echarts.init(document.getElementById("maps"));
      echarts.registerMap("lishui", lishui);
      let maxNumArr = that.obj.datas2.percent.map((value, index, array) => {
        return value.value;
      });
      const maxNum = Math.max(...maxNumArr);
      var options = {
        tooltip: {
          show: false,
          trigger: "item",
          function(name) {
            return "人口总数 " + name;
          },
        },
        visualMap: {
          min: 0,
          max: maxNum,
          show: false,
          realtime: false,
          calculable: true,
          inRange: {
            color: ["#39b1fd", "#f28567"],
          },
        },
        series: [
          {
            name: "仙居县",
            type: "map",
            aspectScale: 1,
            geoIndex: 1,
            zoom: 1.2, //这里是关键，一定要放在 series中
            mapType: "lishui", // 自定义扩展图表类型
            label: {
              emphasis: {
                //对应的鼠标悬浮效果
                show: true,
                textStyle: { color: "#fff" },
              },
            },
            top: 60,
            left: 160,
            roam: false, //地图设置不可拖拽，固定的
            itemStyle: {
              normal: {
                borderWidth: 0.5, //区域边框宽度
                areaColor: "#0e1037",
                borderColor: "black",
                label: {
                  show: true,
                  color: "#fff",
                },
              },
              emphasis: {
                label: {
                  show: false,
                },
                borderColor: "pink",
                areaColor: "#24cccf",
                color: "#fff",
              },
            },
            data: this.obj.datas2.percent,
          },
        ],
      };

      myChart.setOption(options);

      myChart.on("click", function (params) {
        var name = params.name;
        var map_list = that.obj.datas2.map_list;
        that.loading = true;
        for (var i = 0; i < that.obj.datas2.percent.length; i++) {
          var db = that.obj.datas2.percent[i];
          if (name == db.name) {
            db.value = maxNum;
          } else {
            db.value = 0;
          }
          options.series.data = that.obj.datas2.percent;
          myChart.setOption(options);
        }

        for (var i = 0; i < map_list.length; i++) {
          var db = map_list[i];
          if (name == db.name) {
            that.mapsItem = db;
            that.loading = false;
          }
        }

        for (var i = 0; i < that.list.length; i++) {
          if (name == that.list[i].name) {
            that.list[i].flag = true;
          } else {
            that.list[i].flag = false;
          }
        }
      });
    },
    //饼图
    initBing() {
      // 医院分布
      console.log(this.obj.datas4.hosObj.data);
      let pieHosChart = echarts.init(document.getElementById("pieHos"));
      pieHosChart.setOption({
        tooltip: {},
        series: [
          {
            name: "医院分布",
            type: "pie",
            radius: "55%",
            center: ["50%", "50%"],
            left: 0,
            data: this.obj.datas4.hosObj.data,
            color: this.obj.datas4.hosObj.color,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });

      // 疾病分布
      let pieSickChart = echarts.init(document.getElementById("pieSick"));
      pieSickChart.setOption({
        tooltip: {},
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: "55%",
            center: ["50%", "50%"],
            data: this.obj.datas4.sickObj.data,
            color: this.obj.datas4.sickObj.color,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    },
    //环形图
    initPie() {
      let agePieChart = echarts.init(document.getElementById("agepie"));
      agePieChart.setOption({
        legend: {
          show: false,
          legend: this.obj.datas3.ageObj.ageLegend,
        },
        tooltip: {},
        series: [
          {
            name: "年龄组成",
            type: "pie",
            center: ["60%", "60%"],
            radius: ["40%", "55%"],
            label: {
              normal: {
                formatter: "{b}\n{d}%",
                fontSize: 10,
              },
            },
            color: this.obj.datas3.ageObj.color,
            data: this.obj.datas3.ageObj.data,
          },
        ],
      });

      let pieChart = echarts.init(document.getElementById("pie"));
      pieChart.setOption({
        legend: {
          show: false,
          legend: this.obj.datas3.study.studyLegend,
        },
        tooltip: {},
        series: [
          {
            name: "学历组成",
            type: "pie",
            center: ["50%", "60%"],
            radius: ["40%", "55%"],
            label: {
              normal: {
                formatter: "{b}\n{d}%",
                fontSize: 10,
              },
            },
            color: this.obj.datas3.study.color,
            data: this.obj.datas3.study.data,
          },
        ],
      });
    },
    //堆叠图
    initBar() {
      let barChart = echarts.init(document.getElementById("bar"));
      var series = [];
      for (var i = 0; i < this.obj.datas5.dbs.length; i++) {
        var db = this.obj.datas5.dbs[i];
        series.push({
          name: db.name,
          type: "bar",
          stack: "总量",
          barWidth: 15,
          label: {
            normal: {
              show: true,
              position: "insideRight",
            },
          },
          itemStyle: {
            normal: {
              color: db.color, //线条颜色
            },
          },
          data: db.data,
        });
      }
      barChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          orient: "horizontal",
          // show :false,
          y: "bottom",
          data: this.obj.datas5.legend,
          itemGap: 20,
          itemWidth: 18,
          itemHeight: 15,
          textStyle: {
            fontSize: 12,
            color: "#62B4BB",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          top: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.obj.datas5.x,
          axisLabel: {
            textStyle: {
              color: "#62B4BB",
              fontSize: "12",
            },
          },
        },
        yAxis: {
          type: "value",
          data: this.obj.datas5.y,
          axisLabel: {
            textStyle: {
              color: "#62B4BB",
              fontSize: "12",
            },
          },
        },
        series: series,
      });
    },
    //切换全市数据
    backCtiyDate() {
      this.mapsItem = this.cityItem;

      this.obj.datas2.percent.filter((val, index) => {
        val.value = 0;
      });

      this.list.filter((val, index) => {
        val.flag = false;
      });

      this.initMap();
    },
  },
};
</script>

<style lang="scss" scoped>
.show {
  height: 1125px;
  width: 1940px;
  min-width: 2000px;
  min-height: 1125px;
  background: url("../../assets/image/showbig/bg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding: 0px 30px;
}
.main {
  padding: 0% 1%;
  padding-top: 5%;
  // background: red;
  height: 87%;
  color: #fff;
}
.show-item {
  position: relative;
  .date-time {
    position: absolute;
    top: -46px;
    right: 30px;
    text-align: right;
    padding-right: 30px;
    margin: 0px;
  }
}
.rll {
  height: 445px;
  background: url("../../assets/image/showbig/rll_bg.png") no-repeat;
  background-size: 100% 100%;
}
.jb {
  background: url("../../assets/image/showbig/jb_bg.png") no-repeat;
  background-size: 100% 100%;
}
.hx {
  background: url("../../assets/image/showbig/rhx_bg.png") no-repeat;
  background-size: 100% 100%;
}
.ryw {
  background: url("../../assets/image/showbig/ryw_bg.png") no-repeat;
  background-size: 100% 100%;
}
#category,
#bar {
  padding-top: 13%;
}
.jb-item {
  padding-top: 11%;
  // height: 280px;
}
.jb-db {
  padding: 0% 4%;
  font-size: 12px;
  margin-top: -10px;
  .jb-db-item {
    width: 80px;
    margin-bottom: 5px;
    padding-left: 5px;
    .jb-icon {
      margin-right: 5px;
      vertical-align: top;
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 2px;
      background: #fff;
      margin-top: 2px;
    }
    p {
      display: inline-block;
      vertical-align: top;
      color: #5ec0dd;
      margin: 0px;
    }
    .person-span {
    }
  }
}
.hx {
  position: relative;
  .tipss {
    position: absolute;
    width: 100%;
    top: 20%;
    left: 12%;
    image {
      width: 34px;
      height: 32px;
    }
    h2 {
      color: #2bfbff;
      font-size: 18px;
      vertical-align: top;
    }
    p {
      margin-left: 30px;
      vertical-align: top;
    }
  }
}
.hx-item {
  height: 100%;
}
.hx-db-item {
  width: 50%;
  margin-top: 2px;
}
.map {
  height: 445px;
}
.maps {
  position: relative;
  height: 100%;
}
.maps-box {
}
.tips {
  position: absolute;
  left: 0%;
  bottom: 1%;
  width: 168px;
  height: 211px;
  background: url("../../assets/image/showbig/map_bg1.png") no-repeat;
  background-size: 100% 100%;
  .tips-box {
    height: 100%;
    position: relative;
  }
  h2 {
    margin: 0px;
    font-size: 14px;
    font-weight: bold;
    padding-left: 13%;
    padding-top: 2.5%;
    color: #2bfbff;
  }
  p {
    font-size: 10px;
    color: #fff;
    padding-left: 13%;
    margin: 9px 0px;
  }
  .back {
    position: absolute;
    bottom: 2px;
    right: 0px;
    padding: 0px 8px;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
  }
}
.total {
  position: absolute;
  left: 1%;
  top: 1%;
  h2 {
    margin: 0px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
  }
  .img-item {
    margin-left: 5px;
    margin-top: 10px;
  }
  .img-item:first-child {
    margin-left: 0px;
  }
}
.zb {
  padding-top: 60px;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 14px;
}
.zb_tittle {
  height: 104px;
  background: url("../../assets/image/showbig/zb_bg.png") no-repeat;
  background-size: 100% 100%;
}
.zb_list {
  margin-top: -20px;
  height: calc(100vh - 667px);
  overflow-y: auto;
  p {
    padding-left: 20px;
    margin: 0px;
    display: inline-block;
    margin-bottom: 10px;
    max-width: 120px;
    min-width: 120px;
    cursor: pointer;
    color: #ccceff;
  }
}
</style>
